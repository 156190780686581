import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  constructor(
    public afs: AngularFirestore,
  ) { }

  getSinglePage(slug: string): any {
    return this.afs.collection('pages', ref => ref.where('slug', '==', slug).limit(1)).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ))
      .pipe(map(data =>
        data.length > 0 ? data[0] : null),
      );
  }
  getAllPages(): any {
    return this.afs.collection('pages', ref => ref.orderBy('changedAt', 'desc')).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ));
  }

  deletePage(id: string): any {
    return from(this.afs.collection('pages').doc(id).delete());
  }

  updatePage(id: string, page): any {
    return from(this.afs.collection('pages').doc(id).set(page));
  }

  addPage(page): any {
    return from(this.afs.collection('pages').add(page));
  }
}
