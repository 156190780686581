import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../../../core/services/feedback.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-single-feedback-message',
  templateUrl: './admin-single-feedback-message.component.html',
  styleUrls: ['./admin-single-feedback-message.component.scss']
})
export class AdminSingleFeedbackMessageComponent implements OnInit {
  singleMessage;
  singleMessageId: string;
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.singleMessageId = params.id;
      this.feedbackService.getSingleFeedbackMessage(this.singleMessageId).subscribe((data) => {
        this.singleMessage = data;
        this.isLoading = false;
      });
    });
  }

}
