// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBrCpLnlJlUllvPmyxoU06KSA6_pRY-FRM',
    authDomain: 'laglviv1.firebaseapp.com',
    databaseURL: 'https://laglviv1.firebaseio.com',
    projectId: 'laglviv1',
    storageBucket: 'laglviv1.appspot.com',
    messagingSenderId: '3112340553',
    appId: '1:3112340553:web:77453311cd94f27e',
    measurementId: 'G-VM9TV91890',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
