import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as QuillNamespace from 'quill';
// tslint:disable-next-line: variable-name
const Quill: any = QuillNamespace;
import quillImageResizeModule from 'quill-image-resize-module';
// // import { ImageDrop } from 'quill-image-drop-module';
import { AngularFireStorage } from '@angular/fire/storage';
import { from } from 'rxjs';

Quill.register('modules/imageResize', quillImageResizeModule);
// Quill.register('modules/imageDrop', ImageDrop);

// tslint:disable-next-line:variable-name
const BlockEmbed = Quill.import('blots/block/embed');
const toolbar = Quill.import('modules/toolbar');

class Album extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('data-album', value);
    node.contentEditable = 'false';
    // node.setAttribute('data-slug', value.slug);
    node.setAttribute('width', '100%');
    const obj = JSON.parse(value);
    node.innerHTML = `<img src="${
      obj.logo
    }"/><div class="title">${
      obj.title
    }</div>`;
    this.addRemovalButton(node);
    return node;
  }

  static value(node) {
    return node.getAttribute('data-album');
  }

  static addRemovalButton(node) {
    const button = document.createElement('span');
    button.innerHTML = '<i class="fas fa-times-circle"></i>';
    button.onclick = () => node.remove();
    button.contentEditable = 'false';
    node.appendChild(button);
  }
}
Album.blotName = 'album';
Album.className = 'ql-album';
Album.tagName = 'div';

Quill.register(Album, true);

// const albumHandler = function (el) {
//   console.log(el);
//   // Get the cursor location to know where footer will be added.
//   const index = this.quill.getSelection(true).index;
//   // Insert the footer with the footerHTML.
//   this.quill.insertEmbed(index, 'album', 'slug');
// };
// toolbar.DEFAULTS.handlers['album'] = albumHandler;

class Video extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('width', '100%');
    node.setAttribute('controls', 'controls');
    node.setAttribute('data-url', value);
    node.innerHTML = `<source src=${value}>`;
    return node;
  }
  static value(node) {
    return node.getAttribute('data-url');
  }
}

Video.blotName = 'video';
Video.className = 'ql-video';
Video.tagName = 'video';
Quill.register(Video, true);

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditorComponent),
      multi: true,
    },
  ],
})
export class HtmlEditorComponent implements ControlValueAccessor {
  @Input('value') val: string;
  onChange: any = () => { };
  onTouched: any = () => { };
  editorModules = {
    // toolbar: {
    //   container: [
    //     [{ 'font': [] }],
    //     [{ 'size': ['small', false, 'large', 'huge'] }],
    //     ['bold', 'italic', 'underline', 'strike'],
    //     [{ 'header': 1 }, { 'header': 2 }],
    //     [{ 'color': [] }, { 'background': [] }],
    //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //     [{ 'align': [] }],
    //     ['link', 'image']
    //   ]
    // },
    imageResize: {
      modules: [
        'Resize',
        'DisplaySize',
      ],
    },
    // imageDrop: true,
  };
  isShowAlbumsModal = false;
  isShowLoadingModal = false;
  editorInstance;

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  currentPercent = 0;

  constructor(
    private storage: AngularFireStorage,
  ) { }

  created(editorInstance) {
    this.editorInstance = editorInstance;
  }

  addImage(event) {
    if (event[0]) {
      const file = event[0];
      const path = `images/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
            const index = this.editorInstance.getSelection(true).index;
            this.editorInstance.insertEmbed(index, 'image', data, 'user');
            this.editorInstance.update('user');
          },
        ),
      );
    }
  }

  addVideo(event) {
    if (event[0]) {
      const file = event[0];
      const path = `videos/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
            const index = this.editorInstance.getSelection(true).index;
            this.editorInstance.insertEmbed(index, 'video', data, 'user');
            this.editorInstance.update('user');
          },
        ),
      );
    }
  }

  addAlbum(album) {
    const index = this.editorInstance.getSelection(true).index;
    this.editorInstance.insertEmbed(
      index, 'album',
      JSON.stringify({ title: album.title, slug: album.slug, logo: album.images[0].url }) ,
      'user',
    );
    this.editorInstance.update('user');
  }
}
