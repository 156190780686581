import { Component, OnInit } from '@angular/core';
import { MenusService } from '../../../../core/services/menus.service';

@Component({
  selector: 'app-user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.scss'],
})
export class UserNavbarComponent implements OnInit {
  menuList = [];
  socialList = [];
  activeSubmenu: string = null;
  open: boolean = false;

  constructor(
    private menusService: MenusService,
  ) { }

  ngOnInit() {
    this.menusService.getTopMenu().subscribe((data) => {
      this.menuList = data;
    });
    this.menusService.getSocialMenu().subscribe((data) => {
      this.socialList = data;
      this.socialList = this.socialList.filter(elem => elem.isActive);
    });
  }

  triggerSubmenu(name: string) {
    if (this.activeSubmenu === name) {
      this.activeSubmenu = null;
    } else {
      this.activeSubmenu = name;
    }
  }
}
