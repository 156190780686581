import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HomepageSliderService {

  constructor(
    public afs: AngularFirestore,
  ) { }

  getSlides() {
    return from(this.afs.collection('menus').doc('homepage_slider').ref.get())
      .pipe(map(doc => doc.exists ? doc.data() : null))
      .pipe(map(doc =>
        doc && doc.data || []),
      );
  }
  updateSlides(topMenu) {
    return from(this.afs.collection('menus').doc('homepage_slider').set({
      data: topMenu,
    }));
  }
}
