import { Component, OnInit } from '@angular/core';
import { AdministrationService } from '../../../../core/services/administration.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-admin-administration',
  templateUrl: './admin-administration.component.html',
  styleUrls: ['./admin-administration.component.scss'],
})
export class AdminAdministrationComponent implements OnInit {
  administration = [];
  teacher = null;
  searchVal = '';
  isShowDeleteModal = false;
  currentShowAdministrationPerson = null;
  paginationConfig = {
    id: 'teachers',
    itemsPerPage: 12,
    currentPage: 1,
  };
  isLoading = true;

  constructor(
    private administrationService: AdministrationService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.administrationService.getAdministration().subscribe((data) => {
      this.administration = data;
      this.isLoading = false;
    });
  }
  searchAdministration() {
    return this.administration.filter(elem => elem.lastName.toLowerCase()
      .includes(this.searchVal.toLowerCase()));
  }
  showDeleteModal(administrationPerson) {
    this.currentShowAdministrationPerson = administrationPerson;
    this.isShowDeleteModal = true;
  }

  deleteAdministrationPerson() {
    this.administrationService.deleteAdministrationPerson(this.currentShowAdministrationPerson.id)
      .subscribe(
        () => {
          this.administration = this.administration.filter(elem =>
            elem.id !== this.currentShowAdministrationPerson.id);
          this.currentShowAdministrationPerson = null;
          this.isShowDeleteModal = false;
          this.notificationService.sendSuccessNotification('Видалено', 'Вчителя успішно видалено');
        },
        () => {
          this.notificationService.sendErrorNotification('Помилка', 'Помилка при видалені вчителя');
        },
      );
  }
}
