import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { FeedbackService } from '../../../../core/services/feedback.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  isLoading = false;
  feedbackDataForm: FormGroup;
  feedbackData = {
    name: '',
    email: '',
    phone: '',
    messageTopic: '',
    message: '',
    read: false,
    addedAt: null,
  };
  workSchedule = [
    {
      day: 'Понеділок:',
      workingHours: '8:00 - 17:00',
    },
    {
      day: 'Вівторок:',
      workingHours: '8:00 - 17:00',
    },
    {
      day: 'Середа:',
      workingHours: '8:00 - 17:00',
    },
    {
      day: 'Четвер:',
      workingHours: '8:00 - 17:00',
    },
    {
      day: "П'ятниця:",
      workingHours: '8:00 - 17:00',
    },
    {
      day: 'Субота та Неділя:',
      workingHours: 'Вихідний',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private feedbackService: FeedbackService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.feedbackDataForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      email: this.fb.control('', Validators.required),
      phone: this.fb.control('', Validators.required),
      messageTopic: this.fb.control('', Validators.required),
      message: this.fb.control('', Validators.required),
    });
  }
  send() {
    this.feedbackData = { ...this.feedbackData, ...this.feedbackDataForm.value };
    this.feedbackData.addedAt = Timestamp.now();
    this.feedbackService.addFeedbackMessage(this.feedbackData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification(
          'Відправлено',
          'Повідомлення успішно відправлено',
        );
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка відправки повідомлення');
      },
    );
    this.feedbackDataForm.reset();
  }
}
