import { Component, OnInit } from '@angular/core';
import { AlbumsService } from '../../../../core/services/albums.service';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss'],
})
export class AlbumsComponent implements OnInit {
  albums = [];
  isLoading = true;
  paginationConfig = {
    id: 'albums',
    itemsPerPage: 12,
    currentPage: 1,
  };
  constructor(private albumsService: AlbumsService) { }

  ngOnInit() {
    this.albumsService.getAllAlbums().subscribe((data) => {
      this.albums = data;
      this.albums = this.albums.filter(elem => elem.images.length);
      this.isLoading = false;
    });
  }

}
