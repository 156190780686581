import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { from } from 'rxjs';
import { TeachersService } from '../../../../core/services/teachers.service';
import { DepartmentsService } from '../../../../core/services/departments.service';
import { AdministrationService } from '../../../../core/services/administration.service';

@Component({
  selector: 'app-admin-add-edit-adminstration-person',
  templateUrl: './admin-add-edit-adminstration-person.component.html',
  styleUrls: ['./admin-add-edit-adminstration-person.component.scss'],
})
export class AdminAddEditAdminstrationPersonComponent implements OnInit {
  positions: string[] = ['Директор', 'Заступник директора'];
  administration;
  administrationPersonID;
  administrationPerson;
  administrationPersonDataForm: FormGroup;
  isLoading = false;
  isShowLoadingModal = false;
  currentPercent = 0;
  isNew = false;
  newPositionField = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private administrationService: AdministrationService,
    private notificationService: NotificationService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    this.administrationPersonDataForm = this.fb.group({
      firstName: this.fb.control('', Validators.required),
      lastName: this.fb.control('', Validators.required),
      fatherName: this.fb.control('', Validators.required),
      position: this.fb.control(null, Validators.required),
      description: this.fb.control('', Validators.required),
    });
    this.administrationPersonDataForm.get('position').valueChanges.subscribe(() => {
      if (this.administrationPersonDataForm.get('position').value === 'other-position') {
        this.newPositionField = true;
        this.administrationPersonDataForm
          .addControl('newPosition', this.fb.control('', Validators.required));
      } else {
        this.newPositionField = false;
        this.administrationPersonDataForm.removeControl('newPosition');
      }
    });
    this.route.params.subscribe((params) => {
      this.administrationPersonID = params.id;
      this.administrationPersonDataForm.reset();
      this.administrationPerson = {
        firstName: '',
        lastName: '',
        fatherName: '',
        position: null,
        description: '',
        photoUrl: null,
      };
      this.isNew = true;
      this.isLoading = true;
      this.administrationService.getAdministration().subscribe((data) => {
        this.administration = data;
        this.administration.forEach((el) => {
          if (el.id !== this.administrationPersonID && el.position === 'Директор') {
            this.positions = this.positions.filter(el => el !== 'Директор');
          }
        });
        if (this.administrationPersonID) {
          this.administrationPerson = this.administration
            .find(el => el.id === this.administrationPersonID);
          this.isNew = false;
          this.administrationPersonDataForm.setValue({
            firstName: this.administrationPerson.firstName || '',
            lastName: this.administrationPerson.lastName || '',
            fatherName: this.administrationPerson.fatherName || '',
            position: this.administrationPerson.position || null,
            description: this.administrationPerson.description || '',
          });
          if (!this.positions.includes(this.administrationPersonDataForm.get('position').value)) {
            this.administrationPersonDataForm.patchValue({
              position: 'other-position',
              newPosition: this.administrationPersonDataForm.get('position').value,
            });
          }
        }
        this.isLoading = false;
      });
    });
  }
  updatePhoto(event) {
    if (event[0]) {
      const file = event[0];
      const path = `administrationPerson/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
            this.administrationPerson.photoUrl = data;
          },
        ),
      );
    }
  }
  save() {
    this.administrationPerson = {
      ...this.administrationPerson,
      ...this.administrationPersonDataForm.value,
    };
    if (this.administrationPerson.newPosition) {
      this.administrationPerson.position = this.administrationPerson.newPosition;
      delete this.administrationPerson.newPosition;
    }
    const { id, ...teacherData } = this.administrationPerson;
    this.administrationService.updateAdministrationPerson(id, teacherData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
      },
    );
  }
  create() {
    this.administrationPerson = {
      ...this.administrationPerson,
      ...this.administrationPersonDataForm.value,
    };
    if (this.administrationPerson.newPosition) {
      this.administrationPerson.position = this.administrationPerson.newPosition;
      delete this.administrationPerson.newPosition;
    }
    this.administrationService.addAdministrationPerson(this.administrationPerson).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Створено', 'Керівника успішно додано');
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка додавання');
      },
    );
  }
}
