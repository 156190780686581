import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PagesService } from '../../../../core/services/pages.service';
import { transliterate as tr, slugify } from 'transliteration';
import { NotificationService } from '../../../../core/services/notification.service';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
@Component({
  selector: 'app-admin-add-edit-page',
  templateUrl: './admin-add-edit-page.component.html',
  styleUrls: ['./admin-add-edit-page.component.scss'],
})
export class AdminAddEditPageComponent implements OnInit {
  pageSlug;
  page;
  pageDataForm: FormGroup;
  editorInstance;
  isLoading = false;
  isNew = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private pagesService: PagesService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.pageDataForm = this.fb.group({
      title: this.fb.control('', Validators.required),
      slug: this.fb.control('', Validators.required),
      source: this.fb.control('', Validators.required),
    });
    this.route.params.subscribe((params) => {
      this.isLoading = false;
      this.pageSlug = params.slug;
      this.pageDataForm.reset();
      this.page = {
        title: '',
        slug: '',
        editable: true,
        source: '',
        changedAt: null,
        addedAt: null,
      };
      this.isNew = true;
      if (this.pageSlug) {
        this.isLoading = true;
        this.pagesService.getSinglePage(this.pageSlug).subscribe((data) => {
          this.page = data;
          this.isNew = false;
          this.isLoading = false;
          this.pageDataForm.setValue({
            title: this.page.title || '',
            slug: this.page.slug || '',
            source: this.page.source || '',
          });
        });
      }
    });
  }
  addSlug() {
    this.pageDataForm.patchValue({
      slug: slugify(this.pageDataForm.value.title),
    });
  }
  save() {
    this.page = { ...this.page, ...this.pageDataForm.value };
    this.page.changedAt = Timestamp.now();
    const { id, ...pageData } = this.page;

    this.pagesService.updatePage(id, pageData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
      },
    );
  }
  create() {
    this.page = { ...this.page, ...this.pageDataForm.value };
    this.page.changedAt = Timestamp.now();
    this.page.addedAt = this.page.addedAt || Timestamp.now();
    const { id, ...pageData } = this.page;
    this.pagesService.addPage(pageData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Створено', 'Сторінку успішно створено');
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка створення сторінки');
      },
    );
  }
}
