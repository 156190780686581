import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../../../core/services/news.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-admin-news',
  templateUrl: './admin-news.component.html',
  styleUrls: ['./admin-news.component.scss'],
})
export class AdminNewsComponent implements OnInit {
  news = [];
  searchVal = '';
  isShowDeleteModal = false;
  currentShowNews = null;
  paginationConfig = {
    id: 'news',
    itemsPerPage: 12,
    currentPage: 1,
  };
  isLoading = true;
  constructor(
    private newsService: NewsService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.newsService.getAllNews().subscribe((data) => {
      this.news = data;
      this.isLoading = false;
    });
  }
  searchNews() {
    return this.news.filter(elem => elem.title.toLowerCase()
      .includes(this.searchVal.toLowerCase()));
  }
  showDeleteModal(news) {
    this.currentShowNews = news;
    this.isShowDeleteModal = true;
  }

  deleteNews() {
    this.newsService.deleteNews(this.currentShowNews.id).subscribe(
      () => {
        this.news = this.news.filter(elem => elem.id !== this.currentShowNews.id);
        this.currentShowNews = null;
        this.isShowDeleteModal = false;
        this.notificationService.sendSuccessNotification('Видалено', 'Новину успішно видалено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка при видалені новини');
      },
    );
  }
}
