import { Component, OnInit } from '@angular/core';
import { DepartmentsService } from '../../../../core/services/departments.service';
import { TeachersService } from '../../../../core/services/teachers.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-single-department',
  templateUrl: './single-department.component.html',
  styleUrls: ['./single-department.component.scss']
})
export class SingleDepartmentComponent implements OnInit {
  isLoading: boolean = true;
  department;
  departmentSlug;
  teachers = [];

  constructor(
    private departmentsService: DepartmentsService,
    private teachersService: TeachersService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      let departments: any = [];
      this.departmentSlug = params.slug;
      this.departmentsService.getAllDepartments().subscribe((data) => {
        departments = data;
        this.department = departments.find(el => el.slug === this.departmentSlug);
        if (this.department) {
          this.teachersService.getAllTeachersBySlug(this.departmentSlug).subscribe((data) => {
            this.teachers = data;
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
          this.router.navigate(['404']);
        }
      });
    });
  }

}
