import { Component, OnInit } from '@angular/core';
import { AlbumsService } from '../../../../core/services/albums.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-admin-albums',
  templateUrl: './admin-albums.component.html',
  styleUrls: ['./admin-albums.component.scss'],
})
export class AdminAlbumsComponent implements OnInit {
  albums = [];
  loading = true;
  searchVal = '';
  paginationConfig = {
    id: 'albums',
    itemsPerPage: 12,
    currentPage: 1,
  };
  isLoading = true;
  currentShowAlbum = null;
  isShowDeleteModal = false;
  constructor(
    private albumsService: AlbumsService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.albumsService.getAllAlbums().subscribe((data) => {
      // this.albums = [...data, ...data, ...data, ...data, ...data];
      this.albums = data;
      this.isLoading = false;
    });
  }

  showDeleteModal(album) {
    this.currentShowAlbum = album;
    this.isShowDeleteModal = true;
  }

  searchAlbums() {
    return this.albums.filter(elem => elem.title.toLowerCase()
      .includes(this.searchVal.toLowerCase()));
  }

  deleteAlbum() {
    this.albumsService.deleteAlbum(this.currentShowAlbum.id).subscribe(
      () => {
        this.albums = this.albums.filter(elem => elem.id !== this.currentShowAlbum.id);
        this.currentShowAlbum = null;
        this.isShowDeleteModal = false;
        this.notificationService.sendSuccessNotification('Видалено', 'Альбом успішно видалено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка при видалені альбома');
      },
    );
  }
}
