import { Component, OnInit } from '@angular/core';
import { DepartmentsService } from '../../../../core/services/departments.service';

@Component({
  selector: 'app-departments-list',
  templateUrl: './departments-list.component.html',
  styleUrls: ['./departments-list.component.scss'],
})
export class DepartmentsListComponent implements OnInit {
  isLoading: boolean = true;
  departments: any  = [];

  constructor(
    private departmentsService: DepartmentsService,
  ) { }

  ngOnInit() {
    this.departmentsService.getAllDepartments().subscribe((data) => {
      this.departments = data;
      this.isLoading = false;
    });
  }

}
