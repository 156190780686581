import { Component, OnInit } from '@angular/core';
import { TeachersService } from '../../../../core/services/teachers.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { transliterate as tr, slugify } from 'transliteration';
import { DepartmentsService } from '../../../../core/services/departments.service';

@Component({
  selector: 'app-admin-teachers',
  templateUrl: './admin-teachers.component.html',
  styleUrls: ['./admin-teachers.component.scss']
})
export class AdminTeachersComponent implements OnInit {
  teachers = [];
  departments: any = [];
  teacher = null;
  searchVal = '';
  isShowDeleteModal = false;
  currentShowTeacher = null;
  paginationConfig = {
    id: 'teachers',
    itemsPerPage: 12,
    currentPage: 1,
  };
  isLoading = true;

  constructor(
    private teachersService: TeachersService,
    private departmentsService: DepartmentsService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.departmentsService.getAllDepartments().subscribe(data => this.departments = data);
    this.teachersService.getAllTeachers().subscribe((data) => {
      this.teachers = data;
      this.isLoading = false;
    });
  }
  getTeacherDepartment(slug) {
    return this.departments.find(el => el.slug === slug);
  }
  searchTeachers() {
    return this.teachers.filter(elem => elem.lastName.toLowerCase()
      .includes(this.searchVal.toLowerCase()));
  }
  showDeleteModal(teacher) {
    this.currentShowTeacher = teacher;
    this.isShowDeleteModal = true;
  }

  deleteTeacher() {
    this.teachersService.deleteTeacher(this.currentShowTeacher.id).subscribe(
      () => {
        this.teachers = this.teachers.filter(elem => elem.id !== this.currentShowTeacher.id);
        this.currentShowTeacher = null;
        this.isShowDeleteModal = false;
        this.notificationService.sendSuccessNotification('Видалено', 'Вчителя успішно видалено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка при видалені вчителя');
      },
    );
  }
}
