import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsService } from '../../../../core/services/news.service';
import { transliterate as tr, slugify } from 'transliteration';
import { NotificationService } from '../../../../core/services/notification.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { from } from 'rxjs';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
@Component({
  selector: 'app-admin-add-edit-news',
  templateUrl: './admin-add-edit-news.component.html',
  styleUrls: ['./admin-add-edit-news.component.scss'],
})
export class AdminAddEditNewsComponent implements OnInit {
  singleNewsSlug;
  singleNews;
  singleNewsDataForm: FormGroup;
  isLoading = false;
  isShowLoadingModal = false;
  currentPercent = 0;
  isNew = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private newsService: NewsService,
    private notificationService: NotificationService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    this.singleNewsDataForm = this.fb.group({
      title: this.fb.control('', Validators.required),
      slug: this.fb.control('', Validators.required),
      source: this.fb.control('', Validators.required),
    });
    this.route.params.subscribe((params) => {
      this.isLoading = false;
      this.singleNewsSlug = params.slug;
      this.singleNewsDataForm.reset();
      this.singleNews = {
        title: '',
        slug: '',
        souce: '',
        logo: '',
      };
      this.isNew = true;
      if (this.singleNewsSlug) {
        this.isLoading = true;
        this.newsService.getSingleNews(this.singleNewsSlug).subscribe((data) => {
          this.singleNews = data;
          this.isLoading = false;
          this.isNew = false;
          this.singleNewsDataForm.setValue({
            title: this.singleNews.title || '',
            slug: this.singleNews.slug || '',
            source: this.singleNews.source || '',
          });
        });
      }
    });
  }
  updateImage(event) {
    if (event[0]) {
      const file = event[0];
      const path = `images/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
            this.singleNews.logo = data;
          },
        ),
      );
    }
  }
  addSlug() {
    this.singleNewsDataForm.patchValue({
      slug: slugify(this.singleNewsDataForm.value.title),
    });
  }
  save() {
    this.singleNews = { ...this.singleNews, ...this.singleNewsDataForm.value };
    const { id, ...newsData } = this.singleNews;
    this.newsService.updateNews(id, newsData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
      },
    );
  }
  create() {
    this.singleNews = { ...this.singleNews, ...this.singleNewsDataForm.value };
    this.singleNews.addedAt = this.singleNews.addedAt || Timestamp.now();
    this.newsService.addNews(this.singleNews).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Створено', 'Новину успішно створено');
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка створення новини');
      },
    );
  }
}
