import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { from } from 'rxjs';

@Component({
  selector: 'app-html-editor2',
  templateUrl: './html-editor2.component.html',
  styleUrls: ['./html-editor2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditor2Component),
      multi: true,
    },
  ],
})
export class HtmlEditor2Component implements ControlValueAccessor {
  @Input('value') val: string;
  onChange: any = () => { };
  onTouched: any = () => { };
  isShowAlbumsModal = false;
  isShowLoadingModal = false;
  get value() {
    return this.val;
  }
  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
  currentPercent = 0;
  settings = {
    base_url: '/tinymce',
    suffix: '.min',
    content_css: `https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css,
      https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.2/css/all.min.css,
      /assets/styles/html-editor-content.css`,
    height: 570,
    resize: false,
    plugins : [
      'advlist autolink lists link image charmap hr',
      'searchreplace wordcount code fullscreen',
      'media table contextmenu directionality',
      'emoticons paste textcolor colorpicker textpattern noneditable', // codesample
    ],
    toolbar : `undo redo | formatselect  fontsizeselect | bold italic underline forecolor backcolor
      | link image media gallery pdf | alignleft aligncenter alignright alignjustify
      | numlist bullist outdent indent  | removeformat code fullscreen`, // fontselect
    menubar: 'edit insert format tools table',
    menu: {
      edit: {
        title: 'Редагувати',
        items: 'undo redo | cut copy paste pastetext | selectall | searchreplace',
      },
      insert: {
        title: 'Вставити',
        items: 'image link media gallery pdf | charmap directionality emoticons hr',
      },
      format: {
        title: 'Формат',
        items: `bold italic underline strikethrough superscript subscript
         | forecolor backcolor | removeformat`,
      },
      table: {
        title: 'Таблиця',
        items: 'inserttable tableprops deletetable | cell row column',
      },
      tools: {
        title: 'Інструменти', items: 'wordcount code',
      },
    },
    noneditable_noneditable_class: 'non-editable',
    toolbar_drawer: 'sliding',
    fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 28px 30px 32px 34px 36px',
    valid_elements: '*[*]',
    convert_urls: false,
    mobile: {
      plugins: ['autosave', 'lists', 'autolink', 'image'],
      toolbar: ['undo', 'redo', 'bold', 'italic', 'underline',
        'styleselect' , 'fontsizeselect', 'forecolor',
        'image', 'bullist', 'numlist'],
    },
    block_formats: `Paragraph=p;Heading 1=h1;Heading 2=h2;
      Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;`,
    images_upload_handler: (blobInfo, success, failure) => {
      const path = `images/${Date.now()}_${blobInfo.filename()}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, blobInfo.blob());
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            success(data);
          },
        ),
      );
    },
    file_picker_types: 'media',
    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'video/*');
      input.onchange =  () => {
        const file = input.files[0];
        const path = `videos/${Date.now()}_${file.name}`;
        const ref = this.storage.ref(path);
        const task = this.storage.upload(path, file);
        // this.isShowLoadingModal = true;
        // this.currentPercent = 0;
        // task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
        task.then(
          () => from(ref.getDownloadURL()).subscribe(
            (data) => {
              // this.isShowLoadingModal = false;
              // this.currentPercent = 0;
              cb(data, { title: file.name });
            },
          ),
        );
      };
      input.click();
    },
    setup: (editor) => {
      this.editor = editor,
      editor.ui.registry.addMenuItem('gallery', {
        text: 'Gallery',
        onAction: () => this.isShowAlbumsModal = true,
      });
      editor.ui.registry.addButton('gallery', {
        text: '<i class="far fa-images"></i>',
        onAction: () => this.isShowAlbumsModal = true,
      });
      editor.ui.registry.addMenuItem('pdf', {
        text: 'PDF',
        onAction: () => this.onPdfButtonClick(),
      });
      editor.ui.registry.addButton('pdf', {
        text: '<i class="far fa-file-pdf"></i>',
        onAction: () => this.onPdfButtonClick(),
      });
      editor.on('BeforeSetContent', (e) => {
        if (e.content.startsWith('<table ')) {
          e.content = `<div class="table-responsive">${e.content}</div>`;
        }
      });
    },
    // table_default_attributes: {
    //   class: 'table',
    // },
    // additional data
        // style_formats: [
    //   { title: 'Headings', items: [
    //     { title: 'Heading 1', format: 'h1' },
    //     { title: 'Heading 2', format: 'h2' },
    //     { title: 'Heading 3', format: 'h3' },
    //     { title: 'Heading 4', format: 'h4' },
    //     { title: 'Heading 5', format: 'h5' },
    //     { title: 'Heading 6', format: 'h6' }
    //   ]},
    //   { title: 'Inline', items: [
    //     { title: 'Bold', format: 'bold' },
    //     { title: 'Italic', format: 'italic' },
    //     { title: 'Underline', format: 'underline' },
    //     { title: 'Strikethrough', format: 'strikethrough' },
    //     { title: 'Superscript', format: 'superscript' },
    //     { title: 'Subscript', format: 'subscript' },
    //     // { title: 'Code', format: 'code' }
    //   ]},
    //   // { title: 'Blocks', items: [
    //   //   { title: 'Paragraph', format: 'p' },
    //   //   { title: 'Blockquote', format: 'blockquote' },
    //   //   { title: 'Div', format: 'div' },
    //   //   { title: 'Pre', format: 'pre' }
    //   // ]},
    //   // { title: 'Align', items: [
    //   //   { title: 'Left', format: 'alignleft' },
    //   //   { title: 'Center', format: 'aligncenter' },
    //   //   { title: 'Right', format: 'alignright' },
    //   //   { title: 'Justify', format: 'alignjustify' }
    //   // ]}
    // ],
    // codesample_content_css: '/assets/styles/prism-atom.css',
    // codesample_languages: [
    //   { text:'HTML/XML', value:'markup' },
    //   { text:'XML', value:'xml' },
    //   { text:'HTML', value:'html' },
    //   { text:'mathml', value:'mathml' },
    //   { text:'SVG', value:'svg' },
    //   { text:'CSS', value:'css' },
    //   { text:'Clike', value:'clike' },
    //   { text:'Javascript', value:'javascript' },
    //   { text:'ActionScript', value:'actionscript' },
    //   { text:'apacheconf', value:'apacheconf' },
    //   { text:'apl', value:'apl' },
    //   { text:'applescript', value:'applescript' },
    //   { text:'asciidoc', value:'asciidoc' },
    //   { text:'aspnet', value:'aspnet' },
    //   { text:'autoit', value:'autoit' },
    //   { text:'autohotkey', value:'autohotkey' },
    //   { text:'bash', value:'bash' },
    //   { text:'basic', value:'basic' },
    //   { text:'batch', value:'batch' },
    //   { text:'c', value:'c' },
    //   { text:'brainfuck', value:'brainfuck' },
    //   { text:'bro', value:'bro' },
    //   { text:'bison', value:'bison' },
    //   { text:'C#', value:'csharp' },
    //   { text:'C++', value:'cpp' },
    //   { text:'CoffeeScript', value:'coffeescript' },
    //   { text:'ruby', value:'ruby' },
    //   { text:'d', value:'d' },
    //   { text:'dart', value:'dart' },
    //   { text:'diff', value:'diff' },
    //   { text:'docker', value:'docker' },
    //   { text:'eiffel', value:'eiffel' },
    //   { text:'elixir', value:'elixir' },
    //   { text:'erlang', value:'erlang' },
    //   { text:'fsharp', value:'fsharp' },
    //   { text:'fortran', value:'fortran' },
    //   { text:'git', value:'git' },
    //   { text:'glsl', value:'glsl' },
    //   { text:'go', value:'go' },
    //   { text:'groovy', value:'groovy' },
    //   { text:'haml', value:'haml' },
    //   { text:'handlebars', value:'handlebars' },
    //   { text:'haskell', value:'haskell' },
    //   { text:'haxe', value:'haxe' },
    //   { text:'http', value:'http' },
    //   { text:'icon', value:'icon' },
    //   { text:'inform7', value:'inform7' },
    //   { text:'ini', value:'ini' },
    //   { text:'j', value:'j' },
    //   { text:'jade', value:'jade' },
    //   { text:'java', value:'java' },
    //   { text:'JSON', value:'json' },
    //   { text:'jsonp', value:'jsonp' },
    //   { text:'julia', value:'julia' },
    //   { text:'keyman', value:'keyman' },
    //   { text:'kotlin', value:'kotlin' },
    //   { text:'latex', value:'latex' },
    //   { text:'less', value:'less' },
    //   { text:'lolcode', value:'lolcode' },
    //   { text:'lua', value:'lua' },
    //   { text:'makefile', value:'makefile' },
    //   { text:'markdown', value:'markdown' },
    //   { text:'matlab', value:'matlab' },
    //   { text:'mel', value:'mel' },
    //   { text:'mizar', value:'mizar' },
    //   { text:'monkey', value:'monkey' },
    //   { text:'nasm', value:'nasm' },
    //   { text:'nginx', value:'nginx' },
    //   { text:'nim', value:'nim' },
    //   { text:'nix', value:'nix' },
    //   { text:'nsis', value:'nsis' },
    //   { text:'objectivec', value:'objectivec' },
    //   { text:'ocaml', value:'ocaml' },
    //   { text:'oz', value:'oz' },
    //   { text:'parigp', value:'parigp' },
    //   { text:'parser', value:'parser' },
    //   { text:'pascal', value:'pascal' },
    //   { text:'perl', value:'perl' },
    //   { text:'PHP', value:'php' },
    //   { text:'processing', value:'processing' },
    //   { text:'prolog', value:'prolog' },
    //   { text:'protobuf', value:'protobuf' },
    //   { text:'puppet', value:'puppet' },
    //   { text:'pure', value:'pure' },
    //   { text:'python', value:'python' },
    //   { text:'q', value:'q' },
    //   { text:'qore', value:'qore' },
    //   { text:'r', value:'r' },
    //   { text:'jsx', value:'jsx' },
    //   { text:'rest', value:'rest' },
    //   { text:'rip', value:'rip' },
    //   { text:'roboconf', value:'roboconf' },
    //   { text:'crystal', value:'crystal' },
    //   { text:'rust', value:'rust' },
    //   { text:'sas', value:'sas' },
    //   { text:'sass', value:'sass' },
    //   { text:'scss', value:'scss' },
    //   { text:'scala', value:'scala' },
    //   { text:'scheme', value:'scheme' },
    //   { text:'smalltalk', value:'smalltalk' },
    //   { text:'smarty', value:'smarty' },
    //   { text:'SQL', value:'sql' },
    //   { text:'stylus', value:'stylus' },
    //   { text:'swift', value:'swift' },
    //   { text:'tcl', value:'tcl' },
    //   { text:'textile', value:'textile' },
    //   { text:'twig', value:'twig' },
    //   { text:'TypeScript', value:'typescript' },
    //   { text:'verilog', value:'verilog' },
    //   { text:'vhdl', value:'vhdl' },
    //   { text:'wiki', value:'wiki' },
    //   { text:'YAML', value:'yaml' },
    // ],
    // external_plugins: {
    //   phonelink: '/assets/plugins/fonts.js',
    // },
    // font_formats : "Andale Mono=andale mono,times;"+
    //     "Arial=arial,helvetica,sans-serif;"+
    //     "Arial Black=arial black,avant garde;"+
        // "Book Antiqua=book antiqua,palatino;"+
        // "Comic Sans MS=comic sans ms,sans-serif;"+
        // "Courier New=courier new,courier;"+
        // "Georgia=georgia,palatino;"+
        // "Helvetica=helvetica;"+
        // "Impact=impact,chicago;"+
        // "Symbol=symbol;"+
        // "Tahoma=tahoma,arial,helvetica,sans-serif;"+
        // "Terminal=terminal,monaco;"+
        // "Times New Roman=times new roman,times;"+
        // "Trebuchet MS=trebuchet ms,geneva;"+
        // "Verdana=verdana,geneva;"+
        // "Webdings=webdings;"+
        // "Wingdings=wingdings,zapf dingbats",
  };
  dataModel = '';
  editor;
  constructor(
    private storage: AngularFireStorage,
  ) {
  }

  onPdfButtonClick() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'application/pdf');
    input.onchange = () => {
      const file = input.files[0];
      const path = `pdf/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.editor.insertContent(
              `<embed src="${data}" type="application/pdf" width="100%" height="600px"/>`,
            );
            // this.editor.insertContent(
            //   `<div class="editor-pdf non-editable" data-pdf="${data}">${file.name}</div>`,
            // );
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
          },
        ),
      );
    };
    input.click();
  }

  addAlbum(album) {
    this.editor.insertContent(
      `<div class="editor-album non-editable" data-album="${album.slug}">${album.title}</div>`,
    );
  }
}
