import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../../../core/services/feedback.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-admin-feedback-messages',
  templateUrl: './admin-feedback-messages.component.html',
  styleUrls: ['./admin-feedback-messages.component.scss']
})
export class AdminFeedbackMessagesComponent implements OnInit {
  messagesState = false;
  isLoading = true;
  isShowDeleteModal = false;
  feedbackMessages = [];
  paginationConfig = {
    id: 'messages',
    itemsPerPage: 15,
    currentPage: 1,
  };
  currentDeleteMessage = null;

  constructor(
    private feedbackService: FeedbackService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.feedbackService.getAllFeedbackMessages().subscribe((data) => {
      this.feedbackMessages = data;
      this.isLoading = false;
    });
  }
  get messages() {
    return this.feedbackMessages.filter(el => el.read === this.messagesState);
  }
  readMessage(message) {
    const { id, ...messageData } = message;
    if (!messageData.read) {
      messageData.read = message.read = true;
      this.feedbackService.updateTeacher(id, messageData).subscribe();
    }
  }
  showDeleteModal(message) {
    this.currentDeleteMessage = message;
    this.isShowDeleteModal = true;
  }
  deleteMessage() {
    this.feedbackService.deleteFeedbackMessage(this.currentDeleteMessage.id).subscribe(
      () => {
        this.feedbackMessages = this.feedbackMessages.filter(elem =>
          elem.id !== this.currentDeleteMessage.id);
        this.currentDeleteMessage = null;
        this.isShowDeleteModal = false;
        this.notificationService.sendSuccessNotification(
          'Видалено',
          'Повідомлення успішно видалено',
          );
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка видалення повідомлення');
      },
    );
  }
}
