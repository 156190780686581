import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlbumsService {

  constructor(
    public afs: AngularFirestore,
  ) { }

  getSingleAlbum(slug: string): any {
    return this.afs.collection('albums', ref => ref.where('slug', '==', slug).limit(1)).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ))
      .pipe(map(data =>
        data.length > 0 ? data[0] : null),
      );
  }

  getAllAlbums() {
    return this.afs.collection('albums', ref => ref.orderBy('addedAt', 'desc')).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ));
  }
  deleteAlbum(id: string): any {
    return from(this.afs.collection('albums').doc(id).delete());
  }

  updateAlbum(id: string, album): any {
    return from(this.afs.collection('albums').doc(id).set(album));
  }

  addAlbum(album): any {
    return from(this.afs.collection('albums').add(album));
  }

}
