import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MenusService } from '../../../../core/services/menus.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-admin-general-settings',
  templateUrl: './admin-general-settings.component.html',
  styleUrls: ['./admin-general-settings.component.scss']
})
export class AdminGeneralSettingsComponent implements OnInit {
  socialMenu = [];
  changePasswordForm: FormGroup;
  isLoading = true;
  constructor(
    private fb: FormBuilder,
    private menusService: MenusService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.menusService.getSocialMenu().subscribe((data) => {
      this.socialMenu = data;
      this.isLoading = false;
    });
    this.changePasswordForm = this.fb.group({
      previousPassword: this.fb.control('', Validators.required),
      password: this.fb.control('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      passwordConfirmation: this.fb.control('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });

  }

  updatePassword() {
    console.log(this.changePasswordForm.value);
  }
  saveSocialMenu() {
    this.menusService.updateSocialMenu(this.socialMenu).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
      },
    );
  }
}
