import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTransform',
})
export class DateTransformPipe implements PipeTransform {
  transform(date: string, prevFormat?: string, newFormat?: string): string {
    if (!prevFormat || !newFormat) {
      return date;
    }
    return moment(date, prevFormat).format(newFormat);
  }
}
