import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {

  constructor(
    private http: HttpClient,
  ) { }

  getAllDepartments() {
    return this.http.get('./assets/data/departments.json');
  }
}
