export const MenuList = [
  { path: '/', name: 'Homepage' },
  { path: '/page/about', name: 'About' },
  { path: '/page/contacts', name: 'Contacts' },
  { path: '/news', name: 'News' },
];

export const PagesList = [
  {
    path: 'about',
    title: 'About',
    source: `        <h1>ласкаво просимо до гімназії!</h1>
    <blockquote>
        <p><q>Академічна гімназія<br>
            – то історичний пам’ятник Львова,<br>
            і бути її учнем чи виховником – це висока історична відзнака</q>
        </p>
        <cite>
            <p>Йосип Сліпий</p>
        </cite>
    </blockquote>
    <section class="school-history" title="Історія гімназії">
        <h2>З ІСТОРІЇ ГІМНАЗІЇ</h2>
        <p>Заснована декретом цісаря Австро-Угорщини Йосифа ІІ від 24 жовтня 1784 р. Перша гімназія як підрозділ Львівського університету (Академії) отримала назву «академічна». Це була перша в соборній Україні новочасна середня школа класичного типу з рідною мовою викладання (до 1849 – латинська, згодом – німецька, з1874 – українська мова викладання у всіх класах). У гімназії в різні часи працювали видатні педагоги і громадські діячі: професори В. Ільницький (перший директор), І. Боберський, А. Вахнянин, М. Галушинський, І. Зелінський, І. Кокорудз, Ф. Колесса, О. Огоновський, О. Степанів-Дашкевич, С. Шах, доктор філології М. Сабат, доктор права Д. Лукіянович та ін.</p>
        <p>Перша матура (іспит зрілості) відбулася в далекому1878…</p>
        <p>У 20–30-х рр. XX ст. в Академічній гімназії діяли нелегальні юнацькі осередки Української військової організації українських націоналістів, до яких входили учні старших класів. Значна частина учнів гімназії були членами української молодіжної організації Пласт.</p>
        <p>Зберігаючи статус державного навчального закладу за всіх колоніальних адміністрацій, первісток національного шкільництва поневоленого народу спромігся виплекати для Вітчизни і світу чимало чесних, мудрих і мужніх громадян, які клали в підвалини омріяної свободи не тільки свої знання, а й життя.</p>
        <p>У лаві вихованців – піонер національного відродження М. Устиянович, академік М. Возняк, композитор С. Людкевич, полковник Є. Коновалець і генерал Роман Шухевич-Чупринка, митрополит В. Стернюк, академік І.Крип'якевич, письменники О.Маковей,Ю. Опільський, В. Щурат, відомий лікар П. Цимбалістий, славіст І. Свєнціцький, а також А. Курдидик, Р. Сушко, В.Кучабський, М.Кордуба та багато інших.</p>
        <p>У 1944 Академічну гімназію було реформовано у середню загально освітню  школу.</p>
        <p>Львівська академічна відродилася до нового життя із відродженням нашої держави в 1992 р.</p>
        <p>У 1998 р. відроджена Львівська академічна гімназія випустила своїх перших матурантів.</p>
    </section>
    <section class="school-museum" title="Музей гімназії">
        <h2>Музей Історії Львівської академічної гімназії при Національному Університеті «Львівська політехніка»</h2>
        <p><i>Керівник музею: Москалець О.О.</i></p>
        <p>Музей   історії   Львівської   академічної   гімназії   при   НУ   «Львівська політехніка» діє з 1994 року.</p>
        <p>Профіль - історичний. Музей складається з таких експозиційних розділів:</p>
        <ol type="I">
            <li>Історія створення гімназії.</li>
            <li>Директори гімназії.</li>
            <li>Молодіжні організації у Львівській академічній гімназії.</li>
            <li>Роман Шухевич (до 65 - річчя УПА).</li>
            <li>«На зустріч з юністю своєю».</li>
        </ol>
        <p>Фонди музею становлять 176 експонатів. Найцінніші з них:</p>
        <ul>
            <li>«Шкільне свідоцтво» , 1898 року, Рудницького Юрія, оригінал.</li>
            <li>Фото 1903 року українці-абітурієнти Академічної гімназії Львівської.</li>
            <li>Копія - Декрет уряду Австрії від 27 жовтня 1784 року про заснування Української Академічної гімназії.</li>
            <li>Фотографія Олександра Тисовського.</li>
            <li>Фото Івана Боберського.</li>
            <li>Атестат зрілості - похвальний - Тершаковця Михайла,    1895 -1902 років та фото.</li>
            <li>Фото, 1887 рік, професори і матуранти Академічної гімназії.</li>
            <li>Свідоцтво шкільне з 1927 року, оригінал.</li>
            <li>Книга Української Академічної гімназії у Львові  1921 - 1931  рр., оригінал.</li>
            <li>Фото    Головного    редактора    Ювілейного    збірника    «Шкільний надрадник», професора Степана Шаха, Мюнхен, 1960р.</li>
            <li>Декларація світового з'їзду Української Академічної гімназії 11 травня.</li>
        </ul>
    </section>`,
  },
  {
    path: 'contacts',
    title: 'Contacts',
    source: '<div>Contacts</div>',
  },
];

export const NewsList = [
  {
    slug: 'vse-zrazy',
    path: '/news/vse-zrazy',
    logo: '/assets/img/n_konkurssuperhero.jpg',
    title: 'Vse zrazi',
    source: `<p>17 листопада 2019 року в Комп’ютерній Академії “ШАГ” відбудуться відкриті заходи для учнівської молоді міста. Запрошуються учні 3-9 та 9-11 класів на зазначені заходи:</p>
  <ul>
    <li>о 10:30 відбудеться відкритий урок “Розвиток пам’яті та творчого мислення” для учнів 3-9 класів;</li>
    <li>о 12:30 буде проведено День відкритих дверей професійної комп’ютерної освіти для учнів 9-11 класів.</li>
  </ul>
  <p>Захід відбудеться за адресою: Комп’ютерна Академія “ШАГ”, пр-т Миру, 44А</p>
  <p>Реєстрація за телефоном 067 828 85 86 або на сайті Академії kr.itstep.org</p>`,
    category: 'Novyny',
    date_created: 'вересень 28, 1992',
  },
  {
    slug: 'pryvet-vsem',
    path: '/news/pryvet-vsem',
    logo: '/assets/img/n_konkurssuperhero.jpg',
    title: 'Contacts',
    source: `<p>17 листопада 2019 року в Комп’ютерній Академії “ШАГ” відбудуться відкриті заходи для учнівської молоді міста. Запрошуються учні 3-9 та 9-11 класів на зазначені заходи:</p>
    <ul>
      <li>о 10:30 відбудеться відкритий урок “Розвиток пам’яті та творчого мислення” для учнів 3-9 класів;</li>
      <li>о 12:30 буде проведено День відкритих дверей професійної комп’ютерної освіти для учнів 9-11 класів.</li>
    </ul>
    <p>Захід відбудеться за адресою: Комп’ютерна Академія “ШАГ”, пр-т Миру, 44А</p>
    <p>Реєстрація за телефоном 067 828 85 86 або на сайті Академії kr.itstep.org</p>`,
    category: 'Novyny',
    date_created: 'вересень 28, 1992',
  },
];
