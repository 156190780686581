import { AlbumSelectionComponent } from './components/album-selection/album-selection.component';
import { AdminNewsComponent } from './pages/admin-news/admin-news.component';
import {
  AdminAddEditNewsComponent,
} from './pages/admin-add-edit-news/admin-add-edit-news.component';
import {
  AdminAddEditAlbumComponent,
} from './pages/admin-add-edit-album/admin-add-edit-album.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { RouterModule } from '@angular/router';
import { AdminPagesComponent } from './pages/admin-pages/admin-pages.component';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminAlbumsComponent } from './pages/admin-albums/admin-albums.component';
import {
  AdminGeneralSettingsComponent,
} from './pages/admin-general-settings/admin-general-settings.component';
import {
  AdminMenuSettingsComponent,
} from './pages/admin-menu-settings/admin-menu-settings.component';
import {
  AdminSliderSettingsComponent,
} from './pages/admin-slider-settings/admin-slider-settings.component';
import {
  AdminAddEditPageComponent } from './pages/admin-add-edit-page/admin-add-edit-page.component';
import { AdminNavigationComponent } from './components/admin-navigation/admin-navigation.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SlideshowModule } from 'ng-simple-slideshow';
import { NgxGalleryModule } from 'ngx-gallery';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HtmlEditor2Component } from './components/html-editor2/html-editor2.component';
import { AdminTeachersComponent } from './pages/admin-teachers/admin-teachers.component';
import {
  AdminAddEditTeacherComponent,
} from './pages/admin-add-edit-teacher/admin-add-edit-teacher.component';
import {
  AdminAdministrationComponent,
} from './pages/admin-administration/admin-administration.component';
import {
  AdminAddEditAdminstrationPersonComponent,
} from './pages/admin-add-edit-adminstration-person/admin-add-edit-adminstration-person.component';
import { AdminFeedbackMessagesComponent } from './pages/admin-feedback-messages/admin-feedback-messages.component';
import { AdminSingleFeedbackMessageComponent } from './pages/admin-single-feedback-message/admin-single-feedback-message.component';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
@NgModule({
  declarations: [
    AdminDashboardComponent,
    AdminNavigationComponent,
    AdminPagesComponent,
    AdminAlbumsComponent,
    AdminGeneralSettingsComponent,
    AdminMenuSettingsComponent,
    AdminSliderSettingsComponent,
    AdminAddEditAlbumComponent,
    AdminAddEditNewsComponent,
    AdminNewsComponent,
    AdminAddEditPageComponent,
    HtmlEditorComponent,
    HtmlEditor2Component,
    AlbumSelectionComponent,
    AdminTeachersComponent,
    AdminAddEditTeacherComponent,
    AdminAdministrationComponent,
    AdminAddEditAdminstrationPersonComponent,
    AdminFeedbackMessagesComponent,
    AdminSingleFeedbackMessageComponent,
    AdminLoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    QuillModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SlideshowModule,
    NgxGalleryModule,
    DragDropModule,
    EditorModule,
  ],
  providers: [],
})
export class AdminModule { }
