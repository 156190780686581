import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../../core/services/pages.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-admin-pages',
  templateUrl: './admin-pages.component.html',
  styleUrls: ['./admin-pages.component.scss']
})
export class AdminPagesComponent implements OnInit {
  pages = [];
  searchVal = '';
  isShowDeleteModal = false;
  currentShowPage = null;
  paginationConfig = {
    id: 'pages',
    itemsPerPage: 12,
    currentPage: 1,
  };
  isLoading = true;
  constructor(
    private pagesService: PagesService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.pagesService.getAllPages().subscribe((data) => {
      this.pages = data;
      this.isLoading = false;
    });
  }

  searchPages() {
    return this.pages.filter(elem => elem.title.toLowerCase()
      .includes(this.searchVal.toLowerCase()));
  }
  showDeleteModal(page) {
    this.currentShowPage = page;
    this.isShowDeleteModal = true;
  }

  deletePage() {
    this.pagesService.deletePage(this.currentShowPage.id).subscribe(
      () => {
        this.pages = this.pages.filter(el => el.id !== this.currentShowPage.id);
        this.currentShowPage = null;
        this.isShowDeleteModal = false;
        this.notificationService.sendSuccessNotification('Видалено', 'Сторінку успішно видалено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка при видалені сторінки');
      },
    );
  }

}
