import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {

  constructor(
    public afs: AngularFirestore,
  ) { }

  getAdministrationPerson(id: string): any {
    return this.afs.collection('administration').doc(id).get()
    .pipe(map(doc => doc.exists ? ({ id: doc.id, ...doc.data() }) : console.log('No document!'),
    ));
  }

  getAdministration() {
    return this.afs.collection('administration', ref => ref.orderBy('order', 'asc')).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ));
  }
  deleteAdministrationPerson(id: string): any {
    return from(this.afs.collection('administration').doc(id).delete());
  }

  updateAdministrationPerson(id: string, administrationPerson): any {
    return from(this.afs.collection('administration').doc(id).set(administrationPerson));
  }

  addAdministrationPerson(administrationPerson): any {
    return from(this.afs.collection('administration').add(administrationPerson));
  }
}
