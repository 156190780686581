import { AlbumsComponent } from './pages/albums/albums.component';
import { SinglePageComponent } from './pages/single-page/single-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { UserNavbarComponent } from './components/user-navbar/user-navbar.component';
import { UserFooterComponent } from './components/user-footer/user-footer.component';
import { SharedModule } from '../../shared/shared.module';
import { NewsListComponent } from './pages/news-list/news-list.component';
import { SingleNewsComponent } from './pages/single-news/single-news.component';
import { UserRoutingComponent } from './components/user-routing/user-routing.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { SingleAlbumComponent } from './pages/single-album/single-album.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DepartmentsListComponent } from './pages/departments-list/departments-list.component';
import { SingleDepartmentComponent } from './pages/single-department/single-department.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    HomepageComponent,
    NotFoundComponent,
    UserRoutingComponent,
    UserNavbarComponent,
    UserFooterComponent,
    SinglePageComponent,
    NewsListComponent,
    SingleNewsComponent,
    SingleAlbumComponent,
    AlbumsComponent,
    DepartmentsListComponent,
    SingleDepartmentComponent,
    AdministrationComponent,
    ContactsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SlideshowModule,
    NgxGalleryModule,
    NgxPaginationModule,
    NgxExtendedPdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
})
export class UserModule { }
