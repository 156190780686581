import { CookieService } from 'ngx-cookie-service';
import { HomepageSliderService } from './services/homepage-slider.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenusService } from './services/menus.service';
import { NewsService } from './services/news.service';
import { PagesService } from './services/pages.service';
import { AlbumsService } from './services/albums.service';
import { NotificationService } from './services/notification.service';
import { NotificationComponent } from './pages/notification/notification.component';
import { HttpClientModule }   from '@angular/common/http';
import { FeedbackService } from './services/feedback.service';
import { TeachersService } from './services/teachers.service';
import { DepartmentsService } from './services/departments.service';
import { AdministrationService } from './services/administration.service';

@NgModule({
  declarations: [
    NotificationComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    CookieService,
    MenusService,
    NewsService,
    PagesService,
    AlbumsService,
    HomepageSliderService,
    NotificationService,
    AdministrationService,
    DepartmentsService,
    FeedbackService,
    TeachersService,
  ],
  exports: [
    NotificationComponent,
  ],
})
export class CoreModule { }
