import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeachersService {

  constructor(
    public afs: AngularFirestore,
  ) { }

  getSingleTeacher(id: string): any {
    return this.afs.collection('teachers').doc(id).get()
    .pipe(map(doc => doc.exists ? ({ id: doc.id, ...doc.data() }) : console.log('No document!'),
    ));
  }

  getAllTeachers() {
    return this.afs.collection('teachers', ref => ref.orderBy('lastName', 'desc')).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ));
  }
  getAllTeachersBySlug(departmentSlug) {
    return this.afs.collection('teachers', ref => ref.where('departmentSlug', '==', departmentSlug)
      .orderBy('lastName', 'desc')).get()
        .pipe(map(data =>
          data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
        ));
  }

  deleteTeacher(id: string): any {
    return from(this.afs.collection('teachers').doc(id).delete());
  }

  updateTeacher(id: string, teacher): any {
    return from(this.afs.collection('teachers').doc(id).set(teacher));
  }

  addTeacher(teacher): any {
    return from(this.afs.collection('teachers').add(teacher));
  }
}
