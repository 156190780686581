import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../../../core/services/news.service';
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent implements OnInit {
  newsList = [];
  isLoading = true;
  constructor(private newsService: NewsService) { }

  ngOnInit() {
    this.newsService.getAllNews().subscribe((data) => {
      this.newsList = data;
      this.isLoading = false;
    });
  }

}
