import { AlbumsService } from './../../../../core/services/albums.service';
import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PagesService } from '../../../../core/services/pages.service';
// declare let videojs;
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styleUrls: ['./single-page.component.scss'],
})
export class SinglePageComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [
    {
      width: '625px',
      height: '500px',
      previewCloseOnClick: true,
      previewCloseOnEsc: true,
      thumbnailsSwipe: true,
      previewSwipe: true,
      arrowPrevIcon: 'fa fa-chevron-left',
      arrowNextIcon: 'fa fa-chevron-right',
      closeIcon: 'fa fa-times',
    },
    {
      breakpoint: 675,
      width: '100%',
    },
    {
      breakpoint: 575,
      height: '400px',
      thumbnailsColumns: 3,
    },
    {
      breakpoint: 450,
      height: '350px',
      preview: false,
    },
    {
      breakpoint: 380,
      height: '300px',
    },
  ];
  pageData;
  isLoading: boolean = true;
  newsData: any;
  pageParts:any = [];
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private pagesService: PagesService,
    private titleService: Title,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private albumsService: AlbumsService,
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe((routeParams) => {
      // this.pageData = this.pagesService.getPageSource(routeParams.slug);
      // if (this.pageData === null) {
      //   this._router.navigate(['/404']);
      // } else {
      //   this.titleService.setTitle(this.pageData.title);
      // }
      this.isLoading = true;
      this.pagesService.getSinglePage(routeParams.slug).subscribe(
        (data) => {
          this.isLoading = false;
          if (!data) {
            this.router.navigate(['/404']);
          }
          this.pageData = data;
          const element = this.renderer.createElement('div');
          element.innerHTML = data.source;
          let currentPart = element.innerHTML;
          this.pageParts = [];
          while (element.querySelector('.editor-album[data-album]')) {
            const album = element.querySelector('.editor-album[data-album]');
            const albumHTML = album.outerHTML;
            const albumSlug = album.getAttribute('data-album');
            const parts = currentPart.split(albumHTML);
            this.pageParts.push({
              type: 'html',
              data: parts.shift(),
            });
            this.pageParts.push({
              type: 'album',
              data: {
                slug: albumSlug,
                images: [],
              },
            });
            this.albumsService.getSingleAlbum(albumSlug).subscribe((data) => {
              const albumObj = this.pageParts
                .filter(el => el.type === 'album' && el.data.slug === albumSlug);
              albumObj.forEach(album =>
                album.data.images = data.images
                  .map(el => ({ small: el.url, medium: el.url, big: el.url })),
              );
            });
            currentPart = parts.join(albumHTML);
            element.innerHTML = parts.join(albumHTML);
          }
          this.pageParts.push({
            type: 'html',
            data: currentPart,
          });
        });
    });
  }

  // clickOnVideo() {
  //   alert('click');
  // }

}
