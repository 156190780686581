import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AlbumsService } from '../../../../core/services/albums.service';

@Component({
  selector: 'app-album-selection',
  templateUrl: './album-selection.component.html',
  styleUrls: ['./album-selection.component.scss']
})
export class AlbumSelectionComponent implements OnInit {
  @Output() select = new EventEmitter<any>();
  albums = [];
  loading = true;
  paginationConfig = {
    id: 'albumsSelection',
    itemsPerPage: 6,
    currentPage: 1,
  };
  constructor(private albumsService: AlbumsService) { }

  ngOnInit() {
    this.albumsService.getAllAlbums().subscribe((data) => {
      this.albums = data;
      this.albums = this.albums.filter(elem => elem.images.length);
      this.loading = false;
    });
  }
}
