import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MenusService {
  constructor(
    public afs: AngularFirestore,
  ) { }

  getTopMenu() {
    return from(this.afs.collection('menus').doc('top_menu').ref.get())
      .pipe(map(doc => doc.exists ? doc.data() : null))
      .pipe(map(doc =>
        doc && doc.data && doc.data.sort((a, b) => a.order > b.order ? 1 : -1) || []),
      );
  }
  getBottomMenu() {
    return from(this.afs.collection('menus').doc('bottom_menu').ref.get())
      .pipe(map(doc => doc.exists ? doc.data() : null))
      .pipe(map(doc =>
        doc && doc.data && doc.data.sort((a, b) => a.order > b.order ? 1 : -1) || []),
      );
  }
  getSocialMenu() {
    return from(this.afs.collection('menus').doc('social_media').ref.get())
      .pipe(map(doc => doc.exists ? doc.data() : null))
      .pipe(map(doc =>
        doc && doc.data || []),
      );
  }
  updateTopMenu(topMenu) {
    return from(this.afs.collection('menus').doc('top_menu').set({
      data: topMenu,
    }));
  }
  updateBottomMenu(bottomMenu) {
    return from(this.afs.collection('menus').doc('bottom_menu').set({
      data: bottomMenu,
    }));
  }
  updateSocialMenu(socialMenu) {
    return from(this.afs.collection('menus').doc('social_media').set({
      data: socialMenu,
    }));
  }
}
