import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(
    public afs: AngularFirestore,
  ) {
  }

  getSingleNews(slug: string): any {
    return this.afs.collection('news', ref => ref.where('slug', '==', slug).limit(1)).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ))
      .pipe(map(data =>
        data.length > 0 ? data[0] : null),
      );
  }

  getAllNews() {
    return this.afs.collection('news', ref => ref.orderBy('addedAt', 'desc')).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ));
  }

  getFourLastNews() {
    return this.afs.collection('news', ref => ref.orderBy('addedAt', 'desc').limit(4)).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ));
  }

  deleteNews(id: string): any {
    return from(this.afs.collection('news').doc(id).delete());
  }

  updateNews(id: string, news): any {
    return from(this.afs.collection('news').doc(id).set(news));
  }

  addNews(page): any {
    return from(this.afs.collection('news').add(page));
  }
}
