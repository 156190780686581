import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    private afs: AngularFirestore,
  ) { }

  getSingleFeedbackMessage(id: string): any {
    return this.afs.collection('feedback').doc(id).get()
      .pipe(map(doc => doc.exists ? ({ id: doc.id, ...doc.data() }) : console.log('No message!'),
    ));
  }

  getAllFeedbackMessages() {
    return this.afs.collection('feedback', ref => ref.orderBy('addedAt', 'desc')).get()
      .pipe(map(data =>
        data.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      ));
  }
  updateTeacher(id: string, message): any {
    return from(this.afs.collection('feedback').doc(id).set(message));
  }
  deleteFeedbackMessage(id: string): any {
    return from(this.afs.collection('feedback').doc(id).delete());
  }

  addFeedbackMessage(message): any {
    return from(this.afs.collection('feedback').add(message));
  }
}
