import { MouseoverOutsideDirective } from './directives/mouseover-outside.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { DateTransformPipe } from './pipes/date-transform.pipe';
import { DateUnixTransformPipe } from './pipes/dateUnixTransform.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { TouchOutsideDirective } from './directives/touch-outside.directive';
import { RouteTransformerDirective } from './directives/route-transformer.directive';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    DateTransformPipe,
    DateUnixTransformPipe,
    PaginationComponent,
    TouchOutsideDirective,
    ClickOutsideDirective,
    MouseoverOutsideDirective,
    RouteTransformerDirective,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
  ],
  exports: [
    SafeHtmlPipe,
    DateTransformPipe,
    DateUnixTransformPipe,
    PaginationComponent,
    TouchOutsideDirective,
    ClickOutsideDirective,
    MouseoverOutsideDirective,
    RouteTransformerDirective,
  ],
})
export class SharedModule { }
