import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { UserModule } from './modules/user/user.module';
import { AdminModule } from './modules/admin/admin.module';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipe } from './shared/pipes/safeHtml.pipe';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, CONFIG, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from './../environments/environment';
import { DateTransformPipe } from './shared/pipes/date-transform.pipe';
import { DateUnixTransformPipe } from './shared/pipes/dateUnixTransform.pipe';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AdminModule,
    UserModule,
    RouterModule,
    QuillModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    SharedModule,
    CoreModule,
  ],
  providers: [
    AngularFirestore,
    {
      provide: CONFIG,
      useValue: {
        DEBUG_MODE: true,
      },
    },
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
