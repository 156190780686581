import { Component, OnInit } from '@angular/core';
import { MenusService } from '../../../../core/services/menus.service';
@Component({
  selector: 'app-user-footer',
  templateUrl: './user-footer.component.html',
  styleUrls: ['./user-footer.component.scss'],
})
export class UserFooterComponent implements OnInit {
  menuList = [];
  constructor(
    private menusService: MenusService,
  ) { }

  ngOnInit() {
    this.menusService.getBottomMenu().subscribe((data) => {
      this.menuList = data;
    });
  }

}
