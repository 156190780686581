import { Component, OnInit } from '@angular/core';
import { AlbumsService } from '../../../../core/services/albums.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxGalleryOptions } from 'ngx-gallery';
import { transliterate as tr, slugify } from 'transliteration';
import { AngularFireStorage } from '@angular/fire/storage';
import { from } from 'rxjs';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-admin-add-edit-album',
  templateUrl: './admin-add-edit-album.component.html',
  styleUrls: ['./admin-add-edit-album.component.scss'],
})
export class AdminAddEditAlbumComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [
    {
      image: false,
      thumbnails: false,
      width: '0',
      height: '0',
      previewCloseOnClick: true,
      previewCloseOnEsc: true,
      thumbnailsSwipe: true,
      previewSwipe: true,
      arrowPrevIcon: 'fa fa-chevron-left',
      arrowNextIcon: 'fa fa-chevron-right',
      closeIcon: 'fa fa-times',
    },
  ];
  isLoading = false;
  singleAlbumSlug;
  singleAlbum;
  singleAlbumDataForm: FormGroup;
  isShowLoadingModal = false;
  currentPercent = 0;
  isNew = false;
  currentShowPhoto = null;
  isShowDeleteModal = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private albumsService: AlbumsService,
    private storage: AngularFireStorage,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.singleAlbumDataForm = this.fb.group({
      title: this.fb.control('', Validators.required),
      slug: this.fb.control('', Validators.required),
    });
    this.route.params.subscribe((params) => {
      this.isLoading = false;
      this.singleAlbumSlug = params.slug;
      this.singleAlbumDataForm.reset();
      this.singleAlbum = {
        title: '',
        slug: '',
        images: [],
      };
      this.isNew = true;
      if (this.singleAlbumSlug) {
        this.isNew = false;
        this.isLoading = true;
        this.albumsService.getSingleAlbum(this.singleAlbumSlug).subscribe((data) => {
          this.singleAlbum = data;
          this.singleAlbumDataForm.setValue({
            title: this.singleAlbum.title || '',
            slug: this.singleAlbum.slug || '',
          });
          this.isLoading = false;
        });
      }
    });
  }
  addSlug() {
    this.singleAlbumDataForm.patchValue({
      slug: slugify(this.singleAlbumDataForm.value.title),
    });
  }
  save() {
    this.singleAlbum = { ...this.singleAlbum, ...this.singleAlbumDataForm.value };
    const { id, ...albumData } = this.singleAlbum;
    this.albumsService.updateAlbum(id, albumData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
      },
    );
  }
  create() {
    this.singleAlbum = { ...this.singleAlbum, ...this.singleAlbumDataForm.value };
    const albumData = {
      ...this.singleAlbum,
      addedAt: new Date(),
    };
    this.albumsService.addAlbum(albumData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Створено', 'Альбом успішно створено');
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка створення альбома');
      },
    );
  }

  addImage(event) {
    if (event[0]) {
      const file = event[0];
      const path = `images/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
            this.singleAlbum.images.push({ url: data });
          },
        ),
      );
    }
  }

  get galleryImages() {
    return this.singleAlbum && this.singleAlbum.images.map(el => ({
      big: el.url,
    })) || [];
  }

  showDeleteModal(photo) {
    this.currentShowPhoto = photo;
    this.isShowDeleteModal = true;
  }

  deletePhoto() {
    this.singleAlbum.images = this.singleAlbum.images
      .filter(elem => elem.url !== this.currentShowPhoto.url);
    this.currentShowPhoto = null;
    this.isShowDeleteModal = false;
  }
}
