import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { from } from 'rxjs';
import { TeachersService } from '../../../../core/services/teachers.service';
import { DepartmentsService } from '../../../../core/services/departments.service';

@Component({
  selector: 'app-admin-add-edit-teacher',
  templateUrl: './admin-add-edit-teacher.component.html',
  styleUrls: ['./admin-add-edit-teacher.component.scss']
})
export class AdminAddEditTeacherComponent implements OnInit {
  singleTeacherID;
  singleTeacher;
  singleTeacherDataForm: FormGroup;
  departments: any = [];
  isLoading = false;
  isShowLoadingModal = false;
  currentPercent = 0;
  isNew = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private teachersService: TeachersService,
    private departmentsService: DepartmentsService,
    private notificationService: NotificationService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    this.departmentsService.getAllDepartments().subscribe(data => this.departments = data);
    this.singleTeacherDataForm = this.fb.group({
      firstName: this.fb.control('', Validators.required),
      lastName: this.fb.control('', Validators.required),
      fatherName: this.fb.control('', Validators.required),
      rank: this.fb.control('', Validators.required),
      departmentSlug: this.fb.control(null, Validators.required),
      description: this.fb.control('', Validators.required),
    });
    this.route.params.subscribe((params) => {
      this.isLoading = false;
      this.singleTeacherID = params.id;
      this.singleTeacherDataForm.reset();
      this.singleTeacher = {
        firstName: '',
        lastName: '',
        fatherName: '',
        rank: '',
        departmentSlug: null,
        description: '',
        photoUrl: null,
      };
      this.isNew = true;
      if (this.singleTeacherID) {
        this.isLoading = true;
        this.teachersService.getSingleTeacher(this.singleTeacherID).subscribe((data) => {
          this.singleTeacher = data;
          this.isLoading = false;
          this.isNew = false;
          this.singleTeacherDataForm.setValue({
            firstName: this.singleTeacher.firstName || '',
            lastName: this.singleTeacher.lastName || '',
            fatherName: this.singleTeacher.fatherName || '',
            rank: this.singleTeacher.rank || '',
            departmentSlug: this.singleTeacher.departmentSlug || null,
            description: this.singleTeacher.description || '',
          });
        });
      }
    });
  }
  updatePhoto(event) {
    if (event[0]) {
      const file = event[0];
      const path = `teachersPhoto/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
            this.singleTeacher.photoUrl = data;
          },
        ),
      );
    }
  }
  save() {
    this.singleTeacher = { ...this.singleTeacher, ...this.singleTeacherDataForm.value };
    const { id, ...teacherData } = this.singleTeacher;
    this.teachersService.updateTeacher(id, teacherData).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
      },
    );
  }
  create() {
    this.singleTeacher = { ...this.singleTeacher, ...this.singleTeacherDataForm.value };
    this.teachersService.addTeacher(this.singleTeacher).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Створено', 'Вчителя успішно додано');
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка додавання');
      },
    );
  }
}
