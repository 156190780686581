import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NewsService } from '../../../../core/services/news.service';
import { AlbumsService } from '../../../../core/services/albums.service';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';

@Component({
  selector: 'app-single-news',
  templateUrl: './single-news.component.html',
  styleUrls: ['./single-news.component.scss']
})
export class SingleNewsComponent implements OnInit {
  newsData;
  isLoading = true;
  lastFourNews = [];
  galleryOptions: NgxGalleryOptions[] = [
    {
      width: '625px',
      height: '500px',
      previewCloseOnClick: true,
      previewCloseOnEsc: true,
      thumbnailsSwipe: true,
      previewSwipe: true,
      arrowPrevIcon: 'fa fa-chevron-left',
      arrowNextIcon: 'fa fa-chevron-right',
      closeIcon: 'fa fa-times',
    },
    {
      breakpoint: 675,
      width: '100%',
    },
    {
      breakpoint: 575,
      height: '400px',
      thumbnailsColumns: 3,
    },
    {
      breakpoint: 450,
      height: '350px',
      preview: false,
    },
    {
      breakpoint: 380,
      height: '300px',
    },
  ];
  pageData;
  newsParts:any = [];
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private newsService: NewsService,
    private renderer: Renderer2,
    private albumsService: AlbumsService,
  ) { }

  ngOnInit() {
    this.newsService.getFourLastNews().subscribe(
      (data) => {
        this.lastFourNews = data;
      },
    );
    this.activeRoute.params.subscribe((routeParams) => {
      this.isLoading = true;
      this.newsService.getSingleNews(routeParams.slug).subscribe(
        (data) => {
          this.isLoading = false;
          if (!data) {
            this.router.navigate(['/404']);
          }
          this.newsData = data;
          const element = this.renderer.createElement('div');
          element.innerHTML = data.source;
          let currentPart = element.innerHTML;
          this.newsParts = [];
          while (element.querySelector('.editor-album[data-album]')) {
            const album = element.querySelector('.editor-album[data-album]');
            const albumHTML = album.outerHTML;
            const albumSlug = album.getAttribute('data-album');
            const parts = currentPart.split(albumHTML);
            this.newsParts.push({
              type: 'html',
              data: parts.shift(),
            });
            this.newsParts.push({
              type: 'album',
              data: {
                slug: albumSlug,
                images: [],
              },
            });
            this.albumsService.getSingleAlbum(albumSlug).subscribe((data) => {
              const albumObj = this.newsParts
                .filter(el => el.type === 'album' && el.data.slug === albumSlug);
              albumObj.forEach(album =>
                album.data.images = data.images
                  .map(el => ({ small: el.url, medium: el.url, big: el.url })),
              );
            });
            currentPart = parts.join(albumHTML);
            element.innerHTML = parts.join(albumHTML);
          }
          this.newsParts.push({
            type: 'html',
            data: currentPart,
          });
        });
    });
  }

}
