import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[touchOutside]',
})
export class TouchOutsideDirective {
  constructor(private elementRef: ElementRef) { }
  @Output() touchOutside = new EventEmitter();
  @HostListener('document:touchstart', ['$event.target']) ontouchstart(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.touchOutside.emit(targetElement);
    }
  }
}
