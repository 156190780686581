import { MenusService } from './../../../../core/services/menus.service';
import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../../core/services/pages.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-admin-menu-settings',
  templateUrl: './admin-menu-settings.component.html',
  styleUrls: ['./admin-menu-settings.component.scss'],
})
export class AdminMenuSettingsComponent implements OnInit {
  currentMenuPage: string = 'top';
  pageModules = [
    {
      title: 'Головна',
      path: '/',
    },
    {
      title: 'Новини',
      path: '/news',
    },
    {
      title: 'Альбоми',
      path: '/albums',
    },
    {
      title: 'Кафедри',
      path: '/departments',
    },
    {
      title: 'Адміністрація',
      path: '/administration',
    },
    {
      title: 'Контакти',
      path: '/contacts',
    },
  ];
  allPages = [];
  topMenu = [];
  bottomMenu = [];
  currentMenu = [];
  activePageBlock = 'Сторінки';
  submenuListName = '';
  isLoadingTop = true;
  isLoadingBottom = true;
  isLoadingPages = true;
  currentEditSubmenuItem = null;
  currentEditSubmenuName = '';
  constructor(
    private menusService: MenusService,
    private pageService: PagesService,
    private notificationService: NotificationService,
  ) { }

  get isLoading() { return this.isLoadingTop || this.isLoadingBottom || this.isLoadingPages; }

  ngOnInit() {
    this.pageService.getAllPages().subscribe((data) => {
      this.allPages = data;
      this.isLoadingPages = false;
    });
    this.menusService.getTopMenu().subscribe((data) => {
      this.topMenu = data;
      this.currentMenu = JSON.parse(JSON.stringify(this.topMenu));
      this.isLoadingTop = false;
    });
    this.menusService.getBottomMenu().subscribe((data) => {
      this.bottomMenu = data;
      this.isLoadingBottom = false;
    });
  }

  changeMenu(menuArr, menuName) {
    this.currentMenuPage = menuName;
    this.currentMenu = JSON.parse(JSON.stringify(menuArr));
    this.activePageBlock = 'Сторінки';
  }
  showPagesBlock(blockTitle) {
    if (this.activePageBlock === blockTitle) {
      this.activePageBlock = null;
    } else {
      this.activePageBlock = blockTitle;
    }
  }
  getPages() {
    return this.allPages.filter(pageEl => !this.currentMenu
      .find(menuEl => menuEl.name === pageEl.title || menuEl.submenu && menuEl.submenu
        .find(submenuEl => submenuEl.name === pageEl.title)));
  }
  getPageModules() {
    return this.pageModules.filter(modelElem => !this.currentMenu
      .find(menuEl => menuEl.name === modelElem.title || menuEl.submenu && menuEl.submenu
        .find(submenuEl => submenuEl.name === modelElem.title)));
      // .find(menuEl => menuEl.name === modelElem.title));
  }
  getSubmenus() {
    return this.currentMenu.filter(elem => elem.submenu);
  }
  addSubmenuList() {
    this.currentMenu.push({
      name: this.submenuListName,
      order: this.currentMenu.length,
      submenu: [],
    });
    this.submenuListName = '';
  }
  addPageInMenu(page, selectedMenu) {
    const pagePath = page.path || `/page/${page.slug}`;
    if (selectedMenu === 'main-menu') {
      this.currentMenu.push({
        name: page.title,
        order: this.currentMenu.length,
        path: pagePath,
      });
    } else {
      this.currentMenu.forEach((elem) => {
        if (elem.name === selectedMenu) {
          elem.submenu.push({
            name: page.title,
            order: elem.submenu.length,
            path: pagePath,
          });
        }
      });
    }
  }
  // addModuleInMenu(pageModule) {
  //   this.currentMenu.push({
  //     name: pageModule.title,
  //     order: this.currentMenu.length,
  //     path: pageModule.path,
  //   });
  // }
  remakeMenuOrders(menu) {
    menu.forEach((elem, index) => {
      elem.order = index;
      if (elem.submenu) {
        elem.submenu.forEach((elem, index) => {
          elem.order = index;
        });
      }
    });
  }
  deletePageFromMenu(menuItem) {
    this.currentMenu = this.currentMenu.filter((elem) => {
      return elem.name !== menuItem.name;
    });
  }
  deletePageFromSubmenu(menuItem, submenuItem) {
    this.currentMenu[menuItem.order].submenu = menuItem.submenu
      .filter(elem => elem.name !== submenuItem.name);
  }
  cancelChanges() {
    this.currentMenu = this.currentMenuPage === 'top'
      ? JSON.parse(JSON.stringify(this.topMenu))
      : JSON.parse(JSON.stringify(this.bottomMenu));
  }
  saveChanges() {
    if (this.currentMenuPage === 'top') {
      this.remakeMenuOrders(this.currentMenu);
      this.topMenu = JSON.parse(JSON.stringify(this.currentMenu));
      this.menusService.updateTopMenu(this.topMenu).subscribe(
        () => {
          this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
        },
        () => {
          this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
        },
      );
    } else {
      this.remakeMenuOrders(this.currentMenu);
      this.bottomMenu = JSON.parse(JSON.stringify(this.currentMenu));
      this.menusService.updateBottomMenu(this.bottomMenu).subscribe(
        () => {
          this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
        },
        () => {
          this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
        },
      );
    }
  }
  dropMainMenu(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.currentMenu, event.previousIndex, event.currentIndex);
  }
  dropSubmenu(event: CdkDragDrop<string[]>, submenu) {
    moveItemInArray(submenu, event.previousIndex, event.currentIndex);
  }
  saveEditedSubmenuName(menuItem) {
    menuItem.name = this.currentEditSubmenuName;
    this.cancelEditedSubmenuName();
  }
  cancelEditedSubmenuName() {
    this.currentEditSubmenuItem = null;
    this.currentEditSubmenuName = '';
  }
}
