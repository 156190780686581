import { ActivatedRoute, Router } from '@angular/router';
import { AlbumsService } from './../../../../core/services/albums.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';

@Component({
  selector: 'app-single-album',
  templateUrl: './single-album.component.html',
  styleUrls: ['./single-album.component.scss'],
})
export class SingleAlbumComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [
    {
      image: false,
      thumbnails: false,
      width: '0',
      height: '0',
      previewCloseOnClick: true,
      previewCloseOnEsc: true,
      thumbnailsSwipe: true,
      previewSwipe: true,
      arrowPrevIcon: 'fa fa-chevron-left',
      arrowNextIcon: 'fa fa-chevron-right',
      closeIcon: 'fa fa-times',
    },
  ];
  galleryImages: NgxGalleryImage[] = [];
  isLoading = true;
  album;
  constructor(
    private albumsService: AlbumsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((routeParams) => {
      this.isLoading = true;
      this.albumsService.getSingleAlbum(routeParams.slug).subscribe(
        (data) => {
          this.album = data;
          if (!data) {
            this.router.navigate(['/404']);
          }
          this.galleryImages = data.images && data.images.length
            ? data.images.map(el => ({ big: el.url }))
            : [];
          this.isLoading = false;
        });
    });
  }
}
