import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
  selector: 'app-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss'],
})
export class AdminNavigationComponent implements OnInit {
  showMenu: boolean = false;
  showAddMenu: boolean = false;
  menuList = [
    {
      name: 'Головна',
      icon: 'fas fa-tachometer-alt',
      link: '',
    },
    {
      name: 'Повідомлення',
      icon: 'fas fa-envelope',
      link: '/messages',
    },
    {
      name: 'Новини',
      icon: 'fas fa-newspaper',
      link: '/news',
      subMenu: [
        {
          name: 'Всі новини',
          link: '/news',
        },
        {
          name: 'Додати нову',
          link: '/news/add',
        },
      ],
    },
    {
      name: 'Фотоальбоми',
      icon: 'fas fa-images',
      link: '/albums',
      subMenu: [
        {
          name: 'Всі фотоальбоми',
          link: '/albums',
        },
        {
          name: 'Додати новий',
          link: '/albums/add',
        },
      ],
    },
    {
      name: 'Сторінки',
      icon: 'fas fa-window-restore',
      link: '/pages',
      subMenu: [
        {
          name: 'Всі сторінки',
          link: '/pages',
        },
        {
          name: 'Додати нову',
          link: '/pages/add',
        },
      ],
    },
    {
      name: 'Вчителі',
      icon: 'fas fa-chalkboard-teacher',
      link: '/teachers',
      subMenu: [
        {
          name: 'Всі вчителі',
          link: '/teachers',
        },
        {
          name: 'Додати вчителя',
          link: '/teachers/add',
        },
      ],
    },
    {
      name: 'Адміністрація',
      icon: 'fas fa-chalkboard-teacher',
      link: '/administration',
      subMenu: [
        {
          name: 'Всі керівники',
          link: '/administration',
        },
        {
          name: 'Додати керівника',
          link: '/administration/add',
        },
      ],
    },
    {
      name: 'Налаштування',
      icon: 'fas fa-cog',
      link: '/general-settings',
      subMenu: [
        {
          name: 'Загальні налаштування',
          nameMobile: 'Загальні',
          link: '/general-settings',
        },
        {
          name: 'Налаштування меню',
          nameMobile: 'Меню',
          link: '/menu-settings',
        },
        {
          name: 'Налаштування слайдера',
          nameMobile: 'Слайдер',
          link: '/slider-settings',
        },
      ],
    },
  ];

  constructor(
    private router: Router,
    public authService: AuthService,
  ) { }

  ngOnInit() {
  }

  toogleMenu() {
    this.showMenu = !this.showMenu;
  }

  hideAddMenu(subMenu) {
    this.showAddMenu = false;
    // should be replaced in the future
    subMenu.style.display = 'none';
    setTimeout(
      () => subMenu.style.display = '',
      100,
    );
  }

  isActive(route): boolean {
    return this.router.isActive(route, true);
  }
}
