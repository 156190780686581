import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateUnixTransform',
})
export class DateUnixTransformPipe implements PipeTransform {
  transform(date: number, newFormat?: string): string  {
    if (!newFormat) {
      return date.toString();
    }
    return moment.unix(date).format(newFormat);
  }
}
