import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  isError = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      login: this.fb.control('', Validators.required),
      password: this.fb.control('', Validators.required),
    });
  }

  login() {
    const { login, password } = this.loginForm.value;
    if (!this.authService.login(login, password)) {
      this.isError = true;
    }
  }

}
