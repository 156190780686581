import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions } from 'ngx-gallery';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { from } from 'rxjs';
import { HomepageSliderService } from '../../../../core/services/homepage-slider.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-admin-slider-settings',
  templateUrl: './admin-slider-settings.component.html',
  styleUrls: ['./admin-slider-settings.component.scss'],
})
export class AdminSliderSettingsComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [
    {
      image: false,
      thumbnails: false,
      width: '0',
      height: '0',
      previewCloseOnClick: true,
      previewCloseOnEsc: true,
      thumbnailsSwipe: true,
      previewSwipe: true,
      arrowPrevIcon: 'fa fa-chevron-left',
      arrowNextIcon: 'fa fa-chevron-right',
      closeIcon: 'fa fa-times',
    },
  ];
  isLoading = true;
  isShowLoadingModal = false;
  currentPercent = 0;
  currentShowPhoto = null;
  isShowDeleteModal = false;
  slides = [];
  constructor(
    private homepageSlider: HomepageSliderService,
    private storage: AngularFireStorage,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.homepageSlider.getSlides().subscribe((data) => {
      this.slides = data;
      this.isLoading = false;
    });
  }
  save() {
    this.homepageSlider.updateSlides(this.slides).subscribe(
      () => {
        this.notificationService.sendSuccessNotification('Збережено', 'Зміни успішно збережено');
      },
      () => {
        this.notificationService.sendErrorNotification('Помилка', 'Помилка збереження');
      },
    );
  }

  addImage(event) {
    if (event[0]) {
      const file = event[0];
      const path = `images/${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.isShowLoadingModal = true;
      this.currentPercent = 0;
      task.percentageChanges().subscribe(data => this.currentPercent = ~~data);
      task.then(
        () => from(ref.getDownloadURL()).subscribe(
          (data) => {
            this.isShowLoadingModal = false;
            this.currentPercent = 0;
            this.slides.push({ url: data, order: this.slides.length });
          },
        ),
      );
    }
  }

  get galleryImages() {
    return this.slides.map(el => ({
      big: el.url,
    })) || [];
  }

  showDeleteModal(photo) {
    this.currentShowPhoto = photo;
    this.isShowDeleteModal = true;
  }

  deleteSlide() {
    this.slides = this.slides
      .filter(elem => elem.url !== this.currentShowPhoto.url);
    this.currentShowPhoto = null;
    this.isShowDeleteModal = false;
  }
  remakeSliderOrders() {
    this.slides.forEach((elem, index) => {
      elem.order = index;
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.slides, event.previousIndex, event.currentIndex);
    this.remakeSliderOrders();
  }
}
