import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NewsService } from '../../../../core/services/news.service';
import { HomepageSliderService } from '../../../../core/services/homepage-slider.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  title = 'Львівська академічна гімназія';
  lastFourNews = [];
  // imageSources = [
  //   '/assets/img/slider2.jpg',
  //   '/assets/img/slider4.jpg',
  //   '/assets/img/slider12.jpg',
  //   '/assets/img/slider13.jpg',
  //   '/assets/img/slider14.jpg',
  // ];
  slides = [];
  constructor(
    private titleService: Title,
    private newsService: NewsService,
    private homepageSlider: HomepageSliderService,
  ) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    this.homepageSlider.getSlides().subscribe((data) => {
      this.slides = data.map(el => el.url);
    });
    this.newsService.getFourLastNews().subscribe(
      (data) => {
        this.lastFourNews = data;
      },
    );
  }

}
