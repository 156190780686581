import { Component, OnInit } from '@angular/core';
import { AdministrationService } from '../../../../core/services/administration.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  administration = [];
  isLoading: boolean = true;

  constructor(
    private administrationService: AdministrationService,
  ) { }

  ngOnInit() {
    this.administrationService.getAdministration().subscribe((data) => {
      this.administration = data;
      this.isLoading = false;
    });
  }
  get director() {
    return this.administration.find(el => el.position === 'Директор');
  }
  get personal() {
    return this.administration.filter(el => el.position !== 'Директор');
  }
}
