import { Component, OnInit } from '@angular/core';
import { MenuList } from '../../../../../assets/data';

@Component({
  selector: 'app-user-routing',
  templateUrl: './user-routing.component.html',
  styleUrls: ['./user-routing.component.scss']
})
export class UserRoutingComponent implements OnInit {
  menuList = MenuList;
  constructor() { }

  ngOnInit() {
  }

}
