import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[mouseoverOutside]',
})
export class MouseoverOutsideDirective {
  constructor(private elementRef: ElementRef) { }
  @Output() mouseoverOutside = new EventEmitter();
  @HostListener('window:mouseover', ['$event.target']) onClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.mouseoverOutside.emit(targetElement);
    }
  }
}
